import React from 'react';
import PropTypes from 'prop-types';
import '../styles/text.css';
import { FormattedMessage } from 'react-intl';

function Text({
  label,
  name,
  value,
  onChange,
  onBlur,
  error,
}) {
  return (
    <div className="text">
      <label htmlFor={name}>{label}</label>
      <textarea
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error && <span className="error"><FormattedMessage id={error} /></span> }
    </div>
  );
}

Text.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

Text.defaultProps = {
  error: null,
};

export default Text;
