import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import '../styles/vehicle.css';
import * as Helper from '../helpers';

function VehicleHighlight({ data }) {
  const {
    manufacturer,
    model,
    image,
    price,
    prices,
  } = data;

  const title = `${manufacturer.name} ${model}`;

  const minPrice = prices.length
    ? prices.reduce(
      (min, prevPrice) => Math.min(min, prevPrice.value),
      Infinity,
    )
    : price;

  return (
    <div className="vehicle-highlight">
      <div
        className="image"
        style={{
          backgroundImage: `url(https://office.livcar.hr/storage/${image})`,
          maxWidth: window.innerWidth - 64,
        }}
      >
        <h3>{title}</h3>
        <h4>
          <div className="h4-price-kn">
            <FormattedMessage id="currency.from">
              {(label) => <small>{label}</small>}
            </FormattedMessage>
            {minPrice}

            <FormattedMessage id="currency.day">
              {(label) => <small>{label}</small>}
            </FormattedMessage>
          </div>

          <div className="h4-price-eur">
            {Helper.Currency.eurToHrk(minPrice).toFixed(0)}
            <small>HRK</small>
          </div>
        </h4>

        <h5>
          <FormattedMessage id="vehicle.tax">
            {(label) => <small>{label}</small>}
          </FormattedMessage>
          {' '}
          +
          {' '}
          <br />
          <FormattedMessage id="vehicle.insurance">
            {(label) => <small>{label}</small>}
          </FormattedMessage>
        </h5>
      </div>
    </div>
  );
}

VehicleHighlight.propTypes = {
  data: PropTypes.shape({
    manufacturer: PropTypes.shape({ name: PropTypes.string }),
    model: PropTypes.string,
    image: PropTypes.string,
    price: PropTypes.number,
    prices: PropTypes.arrayOf(PropTypes.shape({ value: PropTypes.number })),
  }).isRequired,
};

export default VehicleHighlight;
