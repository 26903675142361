import React from 'react';
import PropTypes from 'prop-types';
import * as Component from '../components';

function Privacy({ language }) {
  return language === 'hr' ? (
    <>
      <Component.Scroll />
      <Component.Container grid>
        <Component.Grid>
          <h1 className="title" style={{ margin: '2rem 0' }}>
            Opći uvjeti za zaštitu osobnih podataka
          </h1>
          <div className="card padded">
            <div className="content">
              <h2>Opće odredbe</h2>
              <p>
                1. Ovi opći uvjeti za zaštitu osobnih podataka
                (u daljnjem tekstu: Opći uvjeti zaštite osobnih podataka)
                odnose se na sadržane u njima osobne podatke i
                koji se pomoću web stranice livcar.hr i svih njenih poddomena,
                kao i servisna mjesta prodajne tvrtke Livcorp d.o.o
                za trgovinu i usluge, Hotel Livris, Rapska 12, Zagreb
              </p>
              <p>
                OIB: 94762205298, u daljnjem tekstu
                &bdquo;stranice i prodajno mjesto&ldquo;,
                obrađuje ili prikuplja i pohranjuje voditelj zbirke osobnih
                podataka tvrtke Livcorp d.o.o. tvrtka za trgovinu i usluge,
                Livcorp d.o.o. Hotel Livris, Rapska 12, Zagreb, od vas kao klijenta.
              </p>
              <p>
                2. Livcorp d.o.o., kao pružatelj usluga (livcar.hr)
                i kao pružatelj usluge iznajmljivanja motornih vozila.
                Molimo pročitajte ove Opće uvjete privatnosti da biste
                lakše razumjeli koje podatke prikupljamo od vas i
                kako ih koristimo.
              </p>
              <p>
                3. Ako imate bilo kakvih pitanja u vezi sa
                zaštitom osobnih podataka, obratite nam se
                putem e-maila na info@livcar.hr.
              </p>
              <p>
                4. Pažljivo pročitajte ove Opće uvjete i
                odredbe za zaštitu osobnih podataka.
                Davanjem vaših osobnih podataka putem
                stranice i označavanjem pristanka na ove
                Opće uvjete zaštite osobnih podataka putem web stranice,
                kao i korištenjem usluga koje pruža Livcorp d.o.o.
                prihvaćate da ste pročitali, razumjeli i prihvaćate
                ove opće uvjete zaštite osobnih podataka i
                pristajete na prikupljanje osobnih podataka
                ili putem web kontakta ili putem obrasca koji
                ćete ispuniti prilikom korištenja Livcorp d.o.o.
                usluga, a sve u skladu s ovim Općim uvjetima
                zaštite osobnih podataka.
              </p>
              <p>
                5. Ako se ne slažete s ovim Općim uvjetima
                zaštite osobnih podataka, napustite,
                ne pristupajte i ne upotrebljavajte web
                stranicu te ne koristite usluge koje pruža Livcorp d.o.o.
              </p>
              <h2>Izmjene Općih uvjeta zaštite osobnih podataka</h2>
              <p>
                6. Livcorp d.o.o. može izmijeniti ili dopuniti
                ove Opće uvjete i odredbe zaštite osobnih podataka
                u bilo kojem trenutku objavljivanjem revidiranog
                teksta na web stranici. Izmjene Općih uvjeta za
                zaštitu osobnih podataka stupaju na snagu odmah
                nakon njihova objavljivanja na web stranici.
              </p>
              <p>
                7. Kao klijent odgovorni ste i dužni pregledati
                i proučiti važeće Opće uvjete i pravila zaštite
                osobnih podataka koji su dostupni na web
                stranici prije svakog osobnog podatka koji navedete.
              </p>
              <h2>Obrađeni osobni podaci i uporaba osobnih podataka</h2>
              <p>
                8. Livcorp d.o.o. prikuplja samo osobne
                podatke bitne za pružanje usluge iznajmljivanja
                vozila svojim klijentima i za buduće marketinške napore,
                ali samo ako ih posjetitelj stranice prihvati.
              </p>
              <p>
                9. Livcorp d.o.o. na prodajnim mjestima i na
                web stranici prikuplja sljedeće osobne podatke o ispitanicima:
                ime i prezime, adresu, datum rođenja, osobni identifikacijski broj,
                osobni matični broj ili broj putovnice, broj vozačke dozvole,
                datum izdavanja vozačke dozvole, kreditne/debitne kartice,
                geografske podatke (kao što su GPS koordinate),
                podatke primljene putem sustava video-nadzora,
                kao obvezujuće podatke i telefonski broj (privatni i /ili poslovni),
                adresu e-pošte, način uporabe stranice i informacije internet providera.
              </p>
              <p>
                10. Ovisno o vrstama aktivnosti,
                neki od podataka koje tražimo označeni su kao obvezujući,
                a neki kao dobrovoljne. Ako ne pružite obvezujuće podatke
                za određenu aktivnost ili uslugu koja ih zahtijeva,
                nećete biti u mogućnosti obavljati aktivnosti ne stranici,
                te Vam neće biti pružena usluga.
              </p>
              <p>
                11. Livcorp d.o.o. koristi osobne podatke za izradu
                baze podataka svojih korisnika, i to sljedeće; ime i prezime,
                adresa, datum rođenja, osobni identifikacijski broj,
                osobni matični broj ili broj putovnice, broj vozačke dozvole,
                datum izdavanja vozačke dozvole u svrhu zaključivanja ugovora o najmu;
                broj kreditne / debitne kartice / kartice tekućeg računa radi
                osiguranja usluge naplate; geografske podatke (poput GPS koordinata)
                radi zaštite imovine Livcorp d.o.o. podatke primljene putem sustava
                video nadzora radi zaštite imovine tvrtke, zaštite klijenta i zaposlenika,
                telefonski broj (privatni i / ili poslovni), a e-mail adresa prikuplja se
                i koristi za eventualni kontakt s klijentima prije i tijekom korištenja usluge,
                kao i za kontakt s klijentima nakon korištenja usluge za analizu zadovoljstva
                kupaca radi poboljšanja usluga, njihove funkcionalnosti, u marketinške svrhe,
                za kontaktiranje i pružanje marketinških informacija,
                poboljšanje našeg oglašavanja i promocije; informacije
                o korištenju web stranica i davatelju internetskih usluga
                za pružanje njihovih usluga sa većom kvalitetom, preciznijim
                i osobnijim pristupom, analizu kvaliteta sadržaja na web
                stranici te dodatno poboljšavanje stranice i sadržaja web
                te prilagođavanje publici koja ju posjećuje.
                Na temelju tih podataka saznajemo koji je sadržaj
                ajpopularniji među publikom. Pristup web stranici
                livcar.hr je besplatan i nije potrebna registracija.
                Davanje osobnih podataka je dobrovoljno.
                Ne obrađujemo više osobnih podataka nego
                što je potrebno za vaše sudjelovanje u bilo
                kojoj aktivnosti na našoj web stranici.
              </p>
              <p>
                12. Slanjem svojih osobnih podataka i
                prihvaćanjem ovih Općih uvjeta zaštite osobnih podataka,
                prihvaćate da Vas Livcorp d.o.o. obavještava o
                promotivnim aktivnostima, proizvodima i
                uslugama koje nude.
              </p>
              <h2>Zaštite osobnih podataka, kršenje postupka</h2>
              <p>
                13. U slučaju pronevjere osobnih podataka,
                Upravitelj će ih obraditi bez odgađanja i,
                ako je to izvedivo, obavijestiti Nadzorno tijelo
                (Agenciju za zaštitu osobnih podataka) i žrtvu kršenja
                najmanje 72 sata nakon nastanka kršenja,
                osim ako nije vjerojatno da kršenje osobnih podataka
                predstavlja rizik za prava i slobode pojedinaca.
                Ako se izvješće nije dogodilo u roku od 72 sata,
                ono se obrađuje s razlozima kašnjenja.
                Smatra se da ozljede uzrokuju fizičku,
                materijalnu ili nematerijalnu štetu pojedincu,
                poput gubitka kontrole osobnih podataka ili
                ograničenja njihovih prava, diskriminacije,
                krađe identiteta ili prijevare, financijskih gubitaka,
                neovlaštene obrnute pseudonimizacije, oštećenja ugleda,
                gubitka povjerljivosti osobni podaci zaštićeni
                poslovnom tajnom ili bilo kakvom drugom ekonomskom
                ili socijalnom štetom dotične osobe.
              </p>
              <h2>Otkrivanje osobnih podataka</h2>
              <p>
                14. Podnošenjem vaših osobnih podataka prema ovim
                Općim uvjetima i uvjetima zaštite osobnih podataka,
                pristajete otkriti takve osobne podatke trećim osobama,
                uključujući, ali ne ograničavajući se na povezane osobe,
                Livcorp d.o.o, njegove članove i njihove povezane osobe za
                evidencije klijenata, evidencije o dovršenim uslugama i
                u marketinške svrhe kako bismo poboljšali
                naše napore u oglašavanju i promociji.
              </p>
              <p>
                15. Svi drugi osobni podaci mogu se otkriti
                trećim osobama isključivo u skladu sa zakonskim
                propisima koji reguliraju zaštitu osobnih podataka.
              </p>
              <h2>Dodaci i ispravke osobnih podataka, pravo na zaborav</h2>
              <p>
                16. Livcorp d.o.o. vjeruje da bi svaki klijent
                trebao biti u stanju osigurati točnost,
                cjelovitost i ažuriranost svojih osobnih podataka.
                Ako smatrate da su vaši osobni podaci nepotpuni,
                netočni ili zastarjeli, kontaktirajte nas e-mailom na:
                info@livcar.hr i zatražite odgovarajuće dodavanje,
                izmjenu ili brisanje osobnih podataka.
              </p>
              <p>
                17. Preporučujemo vam da ažurirate svoje osobne
                podatke čim se oni promijene.
                Možete zatražiti brisanje ili uklanjanje podataka.
              </p>
              <p>
                18. Klijent ima pravo zatražiti od Voditelja
                zaštite osobnih podataka pristup osobnim podacima
                i ispravku ili brisanje osobnih podataka ili
                ograničenje obrade koja se odnosi na klijenta
                ili pravo prigovora na obradu takvih prava prijenosa podataka.
              </p>
              <h2>Zaštita podataka</h2>
              <p>
                19. Livcorp d.o.o. ozbiljno shvaća zaštitu podataka
                i poduzeo je razne mjere predostrožnosti kako bi
                zaštitio vaše osobne podatke. Osobne identifikacijske
                informacije ispitanika čuvaju se na poslužitelju dostupnom
                samo odabranim osobama i pružateljima usluga.
                Livcorp d.o.o. šifrira određene osjetljive podatke
                pomoću SSL (Secure Socket Layer) tehnologije da bi
                se osigurala sigurnost osobnih podataka klijenata.
              </p>
              <p>
                20. Nažalost, nijedan prijenos podataka putem
                Interneta ili bilo koje bežične mreže ne može
                biti 100% siguran i Livcorp d.o.o. ne može jamčiti
                zaštitu bilo kakvih podataka koji se prenose na
                ili sa web stranice i nije odgovoran za postupke
                bilo koje treće strane kojoj su takve informacije dostupne.
              </p>
              <h2>Pravila o kolačićima</h2>
              <p>
                21. Livcar.hr ne koristi kolačiće.
              </p>
              <p>Livcorp d.o.o.</p>
              <p>
                Ured: Hotel Livris, Rapska 12, Zagreb
                <br />
                OIB: 94762205298
                <br />
                MBS: 81192902
                <br />
                IBAN: HR7823600001102731826
              </p>
            </div>
          </div>
        </Component.Grid>
      </Component.Container>
    </>
  ) : (
    <>
      <Component.Scroll />
      <Component.Container grid>
        <Component.Grid>
          <h1 className="title" style={{ margin: '2rem 0' }}>
            General terms and conditions for Personal Data Protection
          </h1>
          <div className="card padded">
            <div className="content">
              <h2>General provisions</h2>
              <p>
                1. These general terms and conditions for the protection of personal
                data (hereinafter: General terms and conditions for Personal Data Protection)
                relate to the personal data contained therein and which,
                using the web site livcar.hr
                and all its subdomains, as well as service points of the sale company Livcorp d.o.o.
                for trade and services, Hotel Livris, Rapska 12, Zagreb
                <br />
                OIB: 94762205298, hereinafter referred to
                as &quot;pages and sales point&quot;, processed or
                collected and stored by the head of the personal data collection of the Livcorp
                d.o.o. company for trade and services, Livcorp d.o.o. Hotel Livris, Rapska 12,
                Zagreb, from you as a respondent.
              </p>
              <p>
                2. Livcorp d.o.o., as a provider of services (livcar.hr) and as a provider of
                rental of motor vehicles. Please read these General Terms of Privacy to help you
                understand what information we collect from you and how we use this information.
              </p>
              <p>
                3. If you have any questions regarding personal data protection,
                please contact us via e-mail at info@livcar.hr
              </p>
              <p>
                4. Carefully read these General Terms and Conditions for Personal
                Data Protection. By providing your personal information through the site and by
                marking your consent to these General Terms and Conditions of Personal Data
                Protection through the Site, as well as using the services provided by Livcorp
                d.o.o. you agree that you have read, understood, and agree to these general terms
                and conditions of the protection of personal data and you consent to the collection
                of personal data or through a web contact or through the form that you will fill out
                when using Livcorp d.o.o. service, all in accordance with this General terms and
                conditions for Personal Data Protection.
              </p>
              <p>
                5. If you do not agree to these General Terms of Personal Data Protection, please
                leave, do not access nor use the Site,
                and do not use the services provided by Livcorp d.o.o.
              </p>
              <h2>Changes to the General Terms of Personal Data Protection</h2>
              <p>
                6. Livcorp d.o.o. may modify or supplement these General Terms and Conditions of
                Personal Data Protection at any time by publishing the revised text on the site.
                Amendments to the General Conditions for the Personal Data Protection will come into
                force immediately after their publication on the site.
              </p>
              <p>
                7. As a respondent, you are responsible and obliged to review and study the valid
                General Terms and Conditions for Personal Data Protection that are available on your
                site before each and every personal information you provide.
              </p>
              <p>Processed personal data and the use of personal data</p>
              <p>
                8. Livcorp d.o.o . collects only the personal information essential for achieving
                the purpose of renting vehicles to its clients, and for future marketing efforts,
                but only if accepted by page visitor.
              </p>
              <p>
                9. Livcorp d.o.o. at the sales points and on the site, collects the following
                personal information about the respondents: name and surname, address, date of
                birth, personal identification number, personal ID number or passport number,
                driver&apos;s license number, date of issuance of driver&apos;s license, credit
                / debit card / geographic data (such as GPS coordinates), data received via
                the video surveillance system, as binding data, and phone number
                (private and / or business), email address,
                page usage and Internet provider information as voluntary data.
              </p>
              <p>
                10. Depending on the types of activities, some of the information we are requesting
                to assign to us is designated as binding and some as voluntary.
                If you do not provide
                binding information for a specific activity or service that requires them, you will
                not be allowed to engage in such activity, relatively you will not be provided such
                service.
              </p>
              <p>
                11. Livcorp d.o.o.produces and uses personal data for the creation of a database of
                its users, and precisely; name and surname, address, date of birth, personal
                identification number, personal ID number or passport number,
                driver&apos;s license number,
                date of issue of driver&apos;s license for the purpose of concluding a rental
                contract; number of credit / debit card / current account card to secure the billing
                service; geographic data (such as GPS coordinates) to protect property of Livcorp
                d.o.o. data received via a video surveillance system
                to protect the company&apos;s assets,
                and protect and secure the client and employee, and the phone number (private and /
                or business), and e-mail address collects and uses for eventual contact with the
                respondents before and during use of the service, and to make contact with the
                respondents after using the service to analyze customer satisfaction in order to
                improve the use of their services, their functionality, for marketing purposes,
                to contact you and deliver marketing information, improve our advertising and
                promotional efforts; information on use of the sites and the Internet provider to
                provide their services with better quality, more precision and with more personal
                approach, analyze the content quality on the website, and to further improve the
                pages and site content and adjust it to the audience that visits it. Based on these
                data we find out which content is most popular among the audience. Access to the
                website livcar.hr is free and no registration is required. Providing personal data
                is voluntary. We do not process more personal information than is necessary for your
                participation in any activity on our site.
              </p>
              <p>
                12. By submitting your personal data and accepting these General Terms of Personal
                Data Protection, you agree that Livcorp d.o.o. notifies you about promotional
                activities, products and services they offer.
              </p>
              <h2>Personal Data Protection Manager, Breach procedure</h2>
              <p>
                13. In the event of a personal data breach, the Manager shall process it without
                unnecessary delay and, if feasible, notify the Supervisory Authority (Personal Data
                Protection Agency) and the victim of the infringement at least 72 hours after the
                occurrence of the infringement, unless it is probable that the violation of the
                personal data causes a risk to individuals&apos; rights and freedoms.
                If the report did
                not take place within 72 hours, it shall be processed with the reasons for the
                delay.
              </p>
              <p>
                Injuries are considered to cause physical, material or non-material damage to an
                individual such as loss of personal data control or restriction of their rights,
                discrimination, identity theft or fraud, financial losses, unauthorized reverse
                pseudonymization, reputation damages, loss of confidentiality of personal data
                protected by business secret or any other economic
                or social damage to the individual concerned.
              </p>
              <h2>Personal data disclosure</h2>
              <p>
                14. By submitting your personal information under these General Terms and Conditions
                of Personal Data Protection,
                you agree to disclose such personal information to third
                parties, including, but not limiting to, related persons to Livcorp d.o.o., its
                members and their affiliated persons for the records of clients,
                records of completed services, and for marketing purposes,
                to improve our advertising and promotional efforts.
              </p>
              <p>
                15. Any other personal data may be disclosed to third parties solely in accordance
                with the statutory regulations governing the protection of personal data.
              </p>
              <h2>Additions and corrections of personal data, the right to be forgotten</h2>
              <p>
                16. Livcorp d.o.o. believes that every respondent should be able to ensure that his
                / her personal data is accurate, complete and up to date.
                If you believe your personal
                information is incomplete, inaccurate or outdated, please contact us by e-mail at
                info@livcar.hr and request the appropriate addition, modification or deletion of
                personal information.
              </p>
              <p>
                17. We encourage you to update your personal information as soon as it changes. You
                can request to delete or remove your information.
              </p>
              <p>
                18. A respondent has the right to request the Personal Data protection manager
                access to personal data and correction or deletion of personal data or the
                restriction of the processing related to the respondent or deletion of personal data
                or the restriction of processing related to the respondent or the right to object to
                the processing of such data transfer rights.
              </p>
              <h2>Data protection</h2>
              <p>
                19. Livcorp d.o.o. takes seriously data protection and has taken various precautions
                to protect your personal information.
                Respondents&apos; personal identification information
                is kept on a server accessible to only chosen persons and providers. Livcorp d.o.o.
                encrypts certain sensitive information using Secure Socket Layer (SSL) technology to
                ensure that respondents&apos; personal information is secure.
              </p>
              <p>
                20. Unfortunately, no data transfer over the Internet, or any wireless network, can
                be 100% secure and Livcorp d.o.o. can not
                guarantee the protection of any information
                transmitted to or from the site and is not responsible for the actions of any third
                party to which such information is made available.
              </p>
              <h2>Cookie policy</h2>
              <p>21. Livcar.hr does not use cookies.</p>
              <p>
                Livcorp d.o.o.
                <br />
                Office: Hotel Livris, Rapska 12, Zagreb
                <br />
                OIB: 94762205298
                <br />
                MBS: 81192902
                <br />
                IBAN: HR7823600001102731826
              </p>
            </div>
          </div>
        </Component.Grid>
      </Component.Container>
    </>
  );
}

Privacy.propTypes = {
  language: PropTypes.oneOf(['hr', 'en']).isRequired,
};

export default Privacy;
