import React from 'react';
import { FormattedMessage } from 'react-intl';

function UsefulLinks() {
  return (
    <div className="card">
      <div className="content">
        <h2><FormattedMessage id="title.usefulLinks" /></h2>
        <FormattedMessage id="url.conditions">
          {(label) => (
            <a aria-labelledby="link.conditions" className="external-link" href={label}><FormattedMessage id="link.conditions" /></a>
          )}
        </FormattedMessage>
        <FormattedMessage id="url.border">
          {(label) => (
            <a aria-labelledby="link.border" className="external-link" href={label}><FormattedMessage id="link.border" /></a>
          )}
        </FormattedMessage>
        <FormattedMessage id="url.forecast">
          {(label) => (
            <a aria-labelledby="link.forecast" className="external-link" href={label}><FormattedMessage id="link.forecast" /></a>
          )}
        </FormattedMessage>
        <h2><FormattedMessage id="title.usefulNumbers" /></h2>
        <span className="contact">
          <span className="phone"><FormattedMessage id="phone.roadside.0" /></span>
          <span className="number"><FormattedMessage id="number.roadside.0" /></span>
        </span>
        <span className="contact">
          <span className="phone"><FormattedMessage id="phone.roadside.1" /></span>
          <span className="number"><FormattedMessage id="number.roadside.1" /></span>
        </span>
        <span className="contact">
          <span className="phone"><FormattedMessage id="phone.roadside.2" /></span>
          <span className="number"><FormattedMessage id="number.roadside.2" /></span>
        </span>
        <br />
        <span className="contact">
          <span className="phone"><FormattedMessage id="phone.conditions.0" /></span>
          <span className="number"><FormattedMessage id="number.conditions.0" /></span>
        </span>
        <span className="contact">
          <span className="phone"><FormattedMessage id="phone.conditions.1" /></span>
          <span className="number"><FormattedMessage id="number.conditions.1" /></span>
        </span>
        <br />
        <span className="contact">
          <span className="phone"><FormattedMessage id="phone.membership" /></span>
          <span className="number"><FormattedMessage id="number.membership" /></span>
        </span>
      </div>
    </div>
  );
}

export default UsefulLinks;
