import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import '../styles/button.css';

function Button(props) {
  const {
    type,
    children,
    variant,
    block,
    to,
    onClick,
  } = props;

  const classNames = `button ${variant} ${block ? 'block' : ''}`;

  if (type === 'link') return <Link to={to} className={classNames}>{children}</Link>;
  if (type === 'submit') return <button type="submit" className={classNames}>{children}</button>;
  if (type === 'label') return <span className={classNames}>{children}</span>;
  return <button type="button" className={classNames} onClick={onClick}>{children}</button>;
}

export default Button;

Button.propTypes = {
  type: PropTypes.oneOf(['submit', 'button', 'link', 'label']),
  children: PropTypes.node,
  variant: PropTypes.oneOf(['primary', 'secondary', 'success', 'inverted']),
  block: PropTypes.bool,
  to: PropTypes.string,
  onClick: PropTypes.func,
};

Button.defaultProps = {
  type: 'submit',
  children: 'Submit',
  variant: 'primary',
  block: false,
  to: '/',
  onClick: () => {},
};
