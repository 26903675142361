import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Calendar from './Calendar';
import * as Helper from '../helpers';
import '../styles/date-time.css';

function DateTime({
  name,
  label,
  time,
  setTime,
}) {
  const [open, setOpen] = useState(false);
  const times = Helper.Time.createTimes(time);
  return (
    <div className="date-time">
      <label htmlFor={name}>{label}</label>
      <input
        id={name}
        name={name}
        type="text"
        value={Helper.Time.formatDate(time)}
        readOnly
        onFocus={() => setOpen(true)}
      />
      <select
        name={name}
        defaultValue={time}
        onChange={(event) => setTime(event.target.value)}
      >
        {times.map((hour) => <option key={hour.hour} value={hour.value}>{`${hour.hour}:00`}</option>)}
      </select>
      <div role="none" className={`backdrop invisible ${open && 'open'}`} onClick={() => setOpen(false)} />
      {open && <Calendar time={time} setTime={setTime} setOpen={setOpen} />}
    </div>
  );
}

DateTime.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  setTime: PropTypes.func.isRequired,
};

export default DateTime;
