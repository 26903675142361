import React from 'react';
import PropTypes from 'prop-types';
import * as Component from '../components';

function NotFount({ language }) {
  return (
    <Component.Container grid>
      <Component.Grid>
        <h1 style={{ textAlign: 'center', minHeight: 'calc(100vh - 16rem)', lineHeight: 'calc(100vh - 16rem)' }}>
          <span style={{ display: 'inline-block', marginLeft: '2rem' }}>{language === 'hr' ? '404 | Stranica nije pronađena.' : '404 | Page not found.'}</span>
        </h1>
      </Component.Grid>
    </Component.Container>
  );
}

NotFount.propTypes = {
  language: PropTypes.oneOf(['hr', 'en']).isRequired,
};

export default NotFount;
