import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import '../styles/input-dummy.css';

function InputDummy({
  label,
  value,
}) {
  return (
    <div className="input-dummy">
      <span className="label">{label}</span>
      <span className="input">{value === 'all' ? <FormattedMessage id="form.all" /> : value}</span>
    </div>
  );
}

InputDummy.propTypes = {
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
};

export default InputDummy;
