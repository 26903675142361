import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import * as Component from '../components';
import questions from '../translations/faq.json';

function Faq({ language }) {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  return (
    <>
      <Component.Scroll />
      <Component.Container grid>
        <Component.Grid>
          <h1 className="title" style={{ margin: '2rem 0' }}>
            <FormattedMessage id="title.faq" />
          </h1>
          <div className="questions">
            {questions[language].map((question, index) => (
              <Component.Question
                key={question.question}
                id={index}
                question={question.question}
                answer={question.answer}
                open={currentQuestion === index}
                setOpen={setCurrentQuestion}
              />
            ))}
          </div>
        </Component.Grid>
      </Component.Container>
    </>
  );
}

Faq.propTypes = {
  language: PropTypes.oneOf(['en', 'hr']).isRequired,
};

export default Faq;
