import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import '../styles/input.css';
import { FormattedMessage } from 'react-intl';

function Input({
  label,
  name,
  value,
  onChange,
  onBlur,
  mask,
  placeholder,
  error,
}) {
  return (
    <div className="input">
      <label htmlFor={name}>{label}</label>
      {mask ? (
        <InputMask
          id={name}
          name={name}
          type="text"
          mask={mask}
          maskPlaceholder={placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      ) : (
        <input
          id={name}
          name={name}
          type="text"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
      )}
      {error && <span className="error"><FormattedMessage id={error} /></span> }
    </div>
  );
}

Input.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  mask: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

Input.defaultProps = {
  mask: null,
  placeholder: null,
  error: null,
};

export default Input;
