import React from 'react';
import PropTypes from 'prop-types';
import '../styles/header-language.css';
import hr from '../images/HR.svg';
import gb from '../images/GB.svg';

function HeaderLanguage({ language, setLanguage }) {
  return (
    <button
      type="button"
      className="header-language"
      onClick={() => setLanguage((prevLanguage) => (prevLanguage === 'en' ? 'hr' : 'en'))}
    >
      <img src={language === 'en' ? gb : hr} alt="Language" />
    </button>
  );
}

HeaderLanguage.propTypes = {
  language: PropTypes.oneOf(['hr', 'en']).isRequired,
  setLanguage: PropTypes.func.isRequired,
};

export default HeaderLanguage;
