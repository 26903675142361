import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@reach/router';
import { FormattedMessage } from 'react-intl';
import '../styles/header-navigation.css';

function HeaderNavigation({ open, setOpen }) {
  return (
    <nav className={`navigation ${open && 'open'}`}>
      <ul>
        <li><Link to="/" onClick={() => setOpen(false)}><FormattedMessage id="navigation.home" /></Link></li>
        <li><Link to="/vehicles" onClick={() => setOpen(false)}><FormattedMessage id="navigation.vehicles" /></Link></li>
        <li><Link to="/long-term-rental" onClick={() => setOpen(false)}><FormattedMessage id="navigation.longTermRental" /></Link></li>
        <li><Link to="/faq" onClick={() => setOpen(false)}><FormattedMessage id="navigation.faq" /></Link></li>
        <li><Link to="/contact" onClick={() => setOpen(false)}><FormattedMessage id="navigation.contact" /></Link></li>
      </ul>
    </nav>
  );
}

HeaderNavigation.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default HeaderNavigation;
