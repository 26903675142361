import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import '../styles/select.css';

function Select({
  options,
  label,
  value,
  name,
  all,
  onChange,
  onBlur,
  formik,
}) {
  return (
    <div className="select">
      <label htmlFor={name}>{label}</label>
      {formik ? (
        <select
          id={name}
          name={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
        >
          {all && (
          <FormattedMessage id="form.all">
            {(text) => <option value="all">{text}</option>}
          </FormattedMessage>
          )}
          {options.map((option) => (
            <option key={option.id} value={option.id}>{option.name}</option>
          ))}
        </select>
      ) : (
        <select
          id={name}
          name={name}
          onChange={(event) => onChange(Number(event.target.value))}
          value={value}
        >
          {all && (
          <FormattedMessage id="form.all">
            {(text) => <option value="all">{text}</option>}
          </FormattedMessage>
          )}
          {options.map((option) => (
            <option key={option.id} value={option.id}>{option.name}</option>
          ))}
        </select>
      )}

    </div>
  );
}

Select.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.node,
    name: PropTypes.node,
  })).isRequired,
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  formik: PropTypes.bool,
  onBlur: PropTypes.func,
  all: PropTypes.bool,
};

Select.defaultProps = {
  all: true,
  formik: false,
  onBlur: () => {},
};

export default Select;
