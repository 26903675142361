import spacetime from 'spacetime';

const timezone = 'Europe/Zagreb';
const now = spacetime.now(timezone);

const minPickup = (() => {
  const result = now.next('hour').add(2, 'hour').startOf('hour');
  if (result.hour() > 19) return result.next('day').hour(8);
  if (result.hour() < 8) return result.hour(8);
  return result;
})();

const minReturn = (() => {
  const result = minPickup.next('hour');
  if (result.hour() > 19) return result.next('day').hour(8);
  if (result.hour() < 8) return result.hour(8);
  return result;
})();

const maxPickup = minPickup.add(1, 'year').hour(21);
const maxReturn = minPickup.add(1, 'year').add(1, 'month').hour(21);

const suggestedPickup = minPickup.hour() < 12 ? minPickup.hour(12) : minPickup.next('day').hour(12);
const suggestedReturn = suggestedPickup.add(1, 'week');

const formatDate = (date) => spacetime(date).goto(timezone).format('numeric-uk');
const formatDateMinusOne = (date) => spacetime(date).subtract(1, 'day').goto(timezone).format('numeric-uk');
const formatTime = (date) => spacetime(date).goto(timezone).format('time-24');
const formatHour = (date) => spacetime(date).goto(timezone).format('hour-24');

const createCalendar = (time, current) => {
  const calendar = [];
  const baseTime = spacetime(time).goto(timezone);
  const currentTime = spacetime(current).goto(timezone);
  const startOfMonth = baseTime.startOf('month').hour(baseTime.hour());
  const endOfMonth = startOfMonth.endOf('month').hour(baseTime.hour());
  const startOfCalendar = startOfMonth.startOf('week').hour(baseTime.hour());
  const endOfCalendar = endOfMonth.endOf('week');
  for (let i = startOfCalendar; i.isBefore(endOfCalendar); i = i.add(1, 'day')) {
    if (i.day() === 1) calendar.push([]);
    const value = i.isSame(minPickup, 'date') && i.isBefore(spacetime(minPickup).goto(timezone)) ? minPickup : i;
    calendar[calendar.length - 1].push({
      value: value.format('iso'),
      date: value.date(),
      active: value.isSame(currentTime, 'date'),
      disabled: !value.isBetween(minPickup, maxReturn, true),
      hidden: !value.isBetween(startOfMonth.hour(0), endOfMonth.hour(23).minute(59).second(59), true),
    });
  }
  return {
    calendar,
    month: baseTime.month(),
    year: baseTime.year(),
    previous: baseTime.last('month').endOf('month').isAfter(minPickup),
    previousMonth: baseTime.subtract(1, 'month').format('iso'),
    next: baseTime.next('month').isBefore(maxReturn),
    nextMonth: baseTime.add(1, 'month').format('iso'),
  };
};

const createTimes = (time) => {
  const times = [];
  const baseTime = spacetime(time);
  const start = baseTime.isSame(minPickup, 'date') ? minPickup : baseTime.hour(8).startOf('hour');
  const end = baseTime.hour(20);
  for (let i = start; i.isBefore(end); i = i.add(1, 'hour')) {
    times.push({
      value: i.format('iso'),
      hour: i.format('hour-24'),
    });
  }
  return times;
};

const isReturnAfterPickup = (pickupTime, returnTime) => {
  const basePickup = spacetime(pickupTime).goto(timezone);
  const baseReturn = spacetime(returnTime).goto(timezone);
  return basePickup.isBefore(baseReturn);
};

const checkDate = (date) => {
  const nums = date.split('/');
  if (!nums.length) return false;
  const time = spacetime(`${nums[2]}/${nums[1]}/${nums[0]}`);
  return time.isValid();
};

const checkMinAge = (date) => {
  const nums = date.split('/');
  if (!nums.length) return false;
  const time = spacetime(`${nums[2]}/${nums[1]}/${nums[0]}`);
  const delta = spacetime().subtract(21, 'year');
  return !time.isAfter(delta);
};

const checkMaxAge = (date) => {
  const nums = date.split('/');
  if (!nums.length) return false;
  const time = spacetime(`${nums[2]}/${nums[1]}/${nums[0]}`);
  const delta = spacetime().subtract(90, 'year');
  return !time.isBefore(delta);
};

const Time = {
  timezone,
  now,
  minPickup: minPickup.format('iso'),
  minReturn: minReturn.format('iso'),
  maxPickup: maxPickup.format('iso'),
  maxReturn: maxReturn.format('iso'),
  suggestedPickup: suggestedPickup.format('iso'),
  suggestedReturn: suggestedReturn.format('iso'),
  formatDate,
  formatDateMinusOne,
  formatTime,
  formatHour,
  createCalendar,
  createTimes,
  isReturnAfterPickup,
  checkDate,
  checkMinAge,
  checkMaxAge,
};

export default Time;
