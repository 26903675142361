import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { navigate } from '@reach/router';
import * as Helper from '../helpers';
import * as Component from '../components';

function Search() {
  const [loading, setLoading] = useState(true);
  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const results = await Helper.Api.get('vehicles');
        setVehicles(Helper.Sort.sortVehicles(results.data, true, true));
        setLoading(false);
      } catch (error) {
        await navigate('/');
      }
    })();
  }, []);

  if (loading) return <Component.Loader />;

  return (
    <>
      <Component.Scroll />
      <Component.Container grid>

        <Component.Grid>
          <h1 className="title" style={{ margin: '2rem 0 0 0' }}>
            <FormattedMessage id="title.vehicles" />
          </h1>
        </Component.Grid>

        {vehicles.map((vehicle) => (
          <Component.Grid key={vehicle.id} medium={6} large={4}>
            <Component.VehicleCard data={vehicle} />
          </Component.Grid>
        ))}

      </Component.Container>
    </>
  );
}

export default Search;
