import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icon from '@mdi/react';
import * as Path from '@mdi/js';
import Button from './Button';
import '../styles/vehicle.css';

function LongTermVehicle({ data }) {
  const {
    id,
    manufacturer,
    model,
    image,
    category,
    transmission,
    fuel,
    seats,
    doors,
    power,
    discount_price: discountPrice,
  } = data;

  const title = `${manufacturer.name} ${model}`;

  return (
    <div className="vehicle">
      <img src={`https://office.livcar.hr/storage/${image}`} alt={title} />
      <h3>{title}</h3>
      <h4>
        <FormattedMessage id="currency.from">
          {(label) => <small>{label}</small>}
        </FormattedMessage>
        {discountPrice}
        <FormattedMessage id="currency.month">
          {(label) => <small>{label}</small>}
        </FormattedMessage>

      </h4>
      <div className="pills">
        <span className="pill">
          <Icon size="1rem" color="white" path={Path.mdiCar} />
          <span>{category.name}</span>
        </span>
        <span className="pill">
          <Icon size="1rem" color="white" path={Path.mdiGasStation} />
          <span><FormattedMessage id={`vehicle.fuel.${fuel}`} /></span>
        </span>
        <br />
        <span className="pill">
          <Icon size="1rem" color="white" path={Path.mdiEngine} />
          <span>
            {power}
            {' '}
            <FormattedMessage id="vehicle.power.hp" />
          </span>
        </span>
        <span className="pill">
          <Icon size="1rem" color="white" path={Path.mdiCarShiftPattern} />
          <span><FormattedMessage id={`vehicle.transmission.${transmission}`} /></span>
        </span>
        <br />
        <span className="pill">
          <Icon size="1rem" color="white" path={Path.mdiCarSeat} />
          <span>{seats}</span>
        </span>
        <span className="pill">
          <Icon size="1rem" color="white" path={Path.mdiCarDoor} />
          <span>{doors}</span>
        </span>
      </div>
      <div className="action">
        <FormattedMessage id="form.more">
          {(label) => <Button type="link" to={`/vehicles/${id}`}>{label}</Button>}
        </FormattedMessage>
      </div>
    </div>
  );
}

LongTermVehicle.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    manufacturer: PropTypes.shape({ name: PropTypes.string }),
    category: PropTypes.shape({ name: PropTypes.string }),
    model: PropTypes.string,
    image: PropTypes.string,
    transmission: PropTypes.oneOf(['M', 'A']),
    fuel: PropTypes.oneOf(['P', 'D']),
    seats: PropTypes.number,
    doors: PropTypes.number,
    power: PropTypes.number,
    discount_price: PropTypes.number,
  }).isRequired,
};

export default LongTermVehicle;
