import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { navigate } from '@reach/router';
import Icon from '@mdi/react';
import * as Path from '@mdi/js';
import * as Component from '../components';
import * as Helper from '../helpers';
import '../styles/card.css';

function Vehicle({ id, language }) {
  const [loading, setLoading] = useState(true);
  const [vehicle, setVehicle] = useState({});

  useEffect(() => {
    (async () => {
      try {
        const response = await Helper.Api.get(`vehicles/${id}`);
        setVehicle(response.data);
        setLoading(false);
        console.log(response);
      } catch (error) {
        console.log(error.response);
        await navigate('/');
      }
    })();
  }, [id]);

  if (loading) return <Component.Loader />;

  const title = `${vehicle.manufacturer.name} ${vehicle.model}`;

  return (
    <>
      <Component.Scroll />
      <Component.Container grid>
        <Component.Grid>
          <h1 className="title" style={{ margin: '2rem 0 0 0' }}>
            {title}
          </h1>
        </Component.Grid>
        <Component.Grid medium={7} large={8}>
          <div className="card">
            <img src={`http://office.livcar.hr/storage/${vehicle.image}`} alt={title} />
            <div className="content">
              {language === 'hr' ? <p>{vehicle.description_hr}</p> : <p>{vehicle.description_en}</p>}
              <div className="pills">
                <span className="pill">
                  <Icon size="1rem" color="white" path={Path.mdiCar} />
                  <FormattedMessage id="vehicle.category" />
                  :
                  <span>{vehicle.category.name}</span>
                </span>
                <span className="pill">
                  <Icon size="1rem" color="white" path={Path.mdiGasStation} />
                  <FormattedMessage id="vehicle.fuel" />
                  :
                  <span><FormattedMessage id={`vehicle.fuel.${vehicle.fuel}`} /></span>
                </span>
                <br />
                <span className="pill">
                  <Icon size="1rem" color="white" path={Path.mdiEngine} />
                  <FormattedMessage id="vehicle.power" />
                  :
                  <span>
                    {vehicle.power}
                    {' '}
                    <FormattedMessage id="vehicle.power.hp" />
                  </span>
                </span>
                <span className="pill">
                  <Icon size="1rem" color="white" path={Path.mdiCarShiftPattern} />
                  <FormattedMessage id="vehicle.transmission" />
                  :
                  <span><FormattedMessage id={`vehicle.transmission.${vehicle.transmission}`} /></span>
                </span>
                <br />
                <span className="pill">
                  <Icon size="1rem" color="white" path={Path.mdiCarSeat} />
                  <FormattedMessage id="vehicle.seats" />
                  :
                  <span>{vehicle.seats}</span>
                </span>
                <span className="pill">
                  <Icon size="1rem" color="white" path={Path.mdiCarDoor} />
                  <FormattedMessage id="vehicle.doors" />
                  :
                  <span>{vehicle.doors}</span>
                </span>
                {vehicle.features.map((feature) => (
                  <span className="pill" key={feature.name_en}>
                    {language === 'hr' ? feature.name_hr : feature.name_en}
                  </span>
                ))}
              </div>
            </div>
          </div>
        </Component.Grid>
        <Component.Grid medium={5} large={4}>
          <Component.UsefulLinks />
        </Component.Grid>
      </Component.Container>
    </>
  );
}

Vehicle.propTypes = {
  id: PropTypes.node,
  language: PropTypes.oneOf(['hr', 'en']).isRequired,
};

Vehicle.defaultProps = {
  id: 0,
};

export default Vehicle;
