import React from 'react';
import PropTypes from 'prop-types';
import '../styles/container.css';

function Container({ children, grid }) {
  return (
    <div className={`container ${grid && 'grid'}`}>
      {children}
    </div>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  grid: PropTypes.bool,
};

Container.defaultProps = {
  grid: false,
};

export default Container;
