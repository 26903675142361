import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Router } from '@reach/router';
import * as Component from './components';
import * as Page from './pages';
import * as Translations from './translations';
import './styles/variables.css';
import './styles/global.css';

function App() {
  const [language, setLanguage] = useState('hr');
  return (
    <IntlProvider locale={language} messages={Translations[language]} onError={() => {}}>
      <Component.Header language={language} setLanguage={setLanguage} />
      <Router>
        <Page.NotFount default language={language} />
        <Page.Home path="/" language={language} />
        <Page.Home path="/success/:code" language={language} />
        <Page.Home path="/thank-you" thankYou language={language} />
        <Page.Home path="/expired" expired language={language} />
        <Page.Search path="/search/pickup/:pickupTime/return/:returnTime/category/:category" />
        <Page.Reservation path="/reservation/:uuid" language={language} />
        <Page.Vehicles path="/vehicles" />
        <Page.Vehicle path="/vehicles/:id" language={language} />
        <Page.Faq path="/faq" language={language} />
        <Page.Contact path="/contact" language={language} />
        <Page.LongTermRental path="/long-term-rental" language={language} />
        <Page.Terms path="/terms" language={language} />
        <Page.Privacy path="/privacy" language={language} />
      </Router>
      <Component.Footer language={language} />
    </IntlProvider>
  );
}

export default App;
