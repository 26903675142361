import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import * as Path from '@mdi/js';
import parse from 'html-react-parser';
import '../styles/question.css';

function MyComponent({
  id,
  question,
  answer,
  open,
  setOpen,
}) {
  return (
    <div className={`question ${open && 'open'}`}>
      <button
        onClick={() => setOpen(id)}
        type="button"
      >
        <span>{question}</span>
        <Icon path={open ? Path.mdiChevronUp : Path.mdiChevronDown} />
      </button>
      <div className="answer">
        {parse(answer)}
      </div>
    </div>
  );
}

MyComponent.propTypes = {
  id: PropTypes.number.isRequired,
  question: PropTypes.string.isRequired,
  answer: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

export default MyComponent;
