import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Icon from '@mdi/react';
import * as Path from '@mdi/js';
import {
  mdiCalendarClock,
  mdiClockOutline,
  mdiCurrencyUsd,
  mdiPiggyBankOutline,
} from '@mdi/js';
import Alert from './Alert';
import '../styles/hero.css';

function Hero({ code, expired, thankYou }) {
  return (
    <div className="hero">
      <div className="container grid">
        {!code && !expired && thankYou && (
        <div className="success">
          <Icon size={2} path={Path.mdiCheckCircleOutline} />
          <h2><FormattedMessage id="home.hero.thankYou" /></h2>
          <p><FormattedMessage id="home.hero.thankYouMessage" /></p>
        </div>
        )}
        {!code && expired && !thankYou && (
        <div className="warning">
          <Icon size={2} path={Path.mdiAlertCircleOutline} />
          <h2><FormattedMessage id="home.hero.expired" /></h2>
          <p><FormattedMessage id="home.hero.expiredMessage" /></p>
        </div>
        )}
        {code && !expired && !thankYou && (
        <div className="success">
          <Icon size={2} path={Path.mdiCheckCircleOutline} />
          <h2><FormattedMessage id="home.hero.success" /></h2>
          <p><FormattedMessage id="home.hero.confirmation" /></p>
          <h2>{code}</h2>
        </div>
        )}
        {!code && !expired && !thankYou && (
        <div className="message">
          <h2><FormattedMessage id="home.hero.title" /></h2>
          <FormattedMessage id="home.hero.content">
            {(label) => (
              <p style={{ fontSize: '0.75rem' }}>{label}</p>
            )}
          </FormattedMessage>
          <FormattedMessage id="home.hero.now">{(label) => <Alert type="primary" text={label} icon={mdiClockOutline} />}</FormattedMessage>
          <FormattedMessage id="home.hero.workingHours">{(label) => <Alert type="primary" text={label} icon={mdiCalendarClock} />}</FormattedMessage>
          <FormattedMessage id="home.hero.insurance">{(label) => <Alert type="primary" text={label} icon={mdiCurrencyUsd} />}</FormattedMessage>
          <FormattedMessage id="home.hero.discount">{(label) => <Alert type="primary" text={label} icon={mdiPiggyBankOutline} />}</FormattedMessage>
        </div>
        )}
      </div>
    </div>
  );
}

Hero.propTypes = {
  code: PropTypes.node,
  expired: PropTypes.bool,
  thankYou: PropTypes.bool,
};

Hero.defaultProps = {
  code: false,
  expired: false,
  thankYou: false,
};

export default Hero;
