import React, { useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/vehicle.css';
import TouchCarousel from 'react-touch-carousel';
import VehicleHighlight from './VehicleHighlight';

function CarouselContainer({ cursor, children }) {
  const [offset, setOffset] = useState(0);

  const getWidth = () => {
    const firstVehicle = document.getElementsByClassName('vehicle-highlight')[0];
    setOffset(firstVehicle.offsetWidth + 32);
  };

  useLayoutEffect(() => {
    getWidth();

    window.addEventListener('resize', getWidth);

    return () => window.removeEventListener('resize', getWidth);
  }, []);

  const translateX = offset * cursor;
  return (
    <div className="vehicle-highlights">
      <div className="vehicle-highlights" style={{ transform: `translate3d(${translateX}px, 0, 0)` }}>
        {children}
      </div>
    </div>
  );
}

CarouselContainer.propTypes = {
  cursor: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired,
};

function Carousel({ data }) {
  const renderCard = (index, modIndex) => (
    <VehicleHighlight key={index} data={data[modIndex]} />
  );

  return (
    <div className="vehicle-highlights">
      <TouchCarousel
        component={CarouselContainer}
        cardCount={data.length}
        renderCard={renderCard}
        autoplay={4500}
        loop
      />
    </div>
  );
}

Carousel.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })).isRequired,
};

export default Carousel;
