import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Icon from '@mdi/react';
import * as Path from '@mdi/js';
import * as Helper from '../helpers';
import '../styles/calendar.css';

function Calendar({
  setTime,
  setOpen,
  time,
}) {
  const [month, setMonth] = useState(time);
  const dates = Helper.Time.createCalendar(month, time);
  return (
    <div className="calendar">
      <div className="controls">
        <button
          type="button"
          disabled={!dates.previous}
          onClick={() => dates.previous && setMonth(dates.previousMonth)}
        >
          <Icon path={Path.mdiMenuLeft} />
        </button>
        <span className="month-year">
          <FormattedMessage id={`calendar.month.${dates.month}`} />
          {' '}
          {dates.year}
        </span>
        <button
          type="button"
          disabled={!dates.next}
          onClick={() => dates.next && setMonth(dates.nextMonth)}
        >
          <Icon path={Path.mdiMenuRight} />
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <FormattedMessage id="calendar.day.mon">{(day) => <th>{day}</th>}</FormattedMessage>
            <FormattedMessage id="calendar.day.tue">{(day) => <th>{day}</th>}</FormattedMessage>
            <FormattedMessage id="calendar.day.wed">{(day) => <th>{day}</th>}</FormattedMessage>
            <FormattedMessage id="calendar.day.thu">{(day) => <th>{day}</th>}</FormattedMessage>
            <FormattedMessage id="calendar.day.fri">{(day) => <th>{day}</th>}</FormattedMessage>
            <FormattedMessage id="calendar.day.sat">{(day) => <th>{day}</th>}</FormattedMessage>
            <FormattedMessage id="calendar.day.sun">{(day) => <th>{day}</th>}</FormattedMessage>
          </tr>
        </thead>
        <tbody>
          {dates.calendar.map((weeks) => (
            <tr key={weeks[0].value}>
              {weeks.map((day) => (
                <td key={day.value}>
                  <button
                    value={day.value}
                    type="button"
                    disabled={day.disabled || day.hidden}
                    className={(day.hidden && 'hidden') || (day.active && 'active') || null}
                    onClick={async () => {
                      if (!day.disabled) {
                        await setTime(day.value);
                        setOpen(false);
                      }
                    }}
                  >
                    {day.date}
                  </button>
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

Calendar.propTypes = {
  setTime: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  time: PropTypes.string.isRequired,
};

export default Calendar;
