const eurToHrk = (minPrice) => minPrice * 7.5345;

const hrkToEur = (minPrice) => minPrice / 7.5345;

const Currency = {
  eurToHrk,
  hrkToEur,
};

export default Currency;
