const sortVehicles = (vehicles, sortByName, sortAsc) => {
  if (sortByName) {
    return vehicles.sort((a, b) => {
      const prev = `${a.manufacturer.name} ${a.model}`.toUpperCase();
      const current = `${b.manufacturer.name} ${b.model}`.toUpperCase();
      if (prev === current) return 0;
      if (prev < current) return sortAsc ? -1 : 1;
      return sortAsc ? 1 : -1;
    });
  }
  return vehicles.sort((a, b) => {
    const prev = a.prices.length
      ? a.prices.reduce((min, prevPrice) => Math.min(min, prevPrice.value), Infinity)
      : a.price;
    const current = b.prices.length
      ? b.prices.reduce((min, prevPrice) => Math.min(min, prevPrice.value), Infinity)
      : b.price;
    return sortAsc ? prev - current : current - prev;
  });
};

const Sort = {
  sortVehicles,
};

export default Sort;
