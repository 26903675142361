import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useFormik } from 'formik';
import Icon from '@mdi/react';
import * as Path from '@mdi/js';
import { navigate } from '@reach/router';
import * as Component from '../components';
import * as Helper from '../helpers';

function Contact({ language }) {
  const [processing, setProcessing] = useState(false);

  const initialValues = {
    name: '',
    phone: '',
    email: '',
    message: '',
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'error.required';
    } else if (values.name.length > 50) {
      errors.name = 'error.tooLong';
    }

    if (!values.phone) {
      errors.phone = 'error.required';
    } else if (values.phone.length < 8 || values.phone.length > 20) {
      errors.phone = 'error.phone';
    }

    if (!values.email) {
      errors.email = 'error.required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'error.email';
    }

    if (!values.message) {
      errors.message = 'error.required';
    }

    return errors;
  };

  const onSubmit = async (values) => {
    try {
      setProcessing(true);
      await Helper.Api.post('contact', { ...values, language }, { timeout: 0 });
      await navigate('/thank-you');
    } catch (error) {
      await navigate('/');
    }
  };

  const formik = useFormik({ initialValues, validate, onSubmit });

  if (processing) {
    return (
      <Component.Container grid>
        <Component.Grid>
          <h1 style={{ textAlign: 'center', minHeight: 'calc(100vh - 16rem)', lineHeight: 'calc(100vh - 16rem)' }}>
            <Icon size={2} spin color="#fff" path={Path.mdiLoading} />
            <span style={{ display: 'inline-block', marginLeft: '2rem' }}>{language === 'hr' ? 'Upit u obradi...' : 'Your message is being sent...'}</span>
          </h1>
        </Component.Grid>
      </Component.Container>
    );
  }

  return (
    <>
      <Component.Scroll />
      <Component.Container grid>

        <Component.Grid>
          <h1 className="title" style={{ margin: '2rem 0 0 0' }}>
            <FormattedMessage id="title.getInTouch" />
          </h1>
        </Component.Grid>

        <Component.Grid medium={7} large={8}>
          <form onSubmit={formik.handleSubmit}>
            <FormattedMessage id="form.nameSurname">
              {(label) => (
                <Component.Input
                  label={label}
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.name && formik.errors.name}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="form.email">
              {(label) => (
                <Component.Input
                  label={label}
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && formik.errors.email}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="form.phone">
              {(label) => (
                <Component.Input
                  label={label}
                  name="phone"
                  value={formik.values.phone}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.phone && formik.errors.phone}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="form.message">
              {(label) => (
                <Component.Text
                  label={label}
                  name="message"
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.message && formik.errors.message}
                />
              )}
            </FormattedMessage>
            <FormattedMessage id="form.send">{(label) => (<Component.Button>{label}</Component.Button>)}</FormattedMessage>
          </form>

          <div className="contact-details" style={{ marginTop: '2rem' }}>
            <div className="item">
              <Icon path={Path.mdiMapMarker} />
              <span>Rapska 12, 10000, Zagreb</span>
            </div>
            <div className="item">
              <Icon path={Path.mdiPhoneClassic} />
              <span>+385 98 633 893</span>
            </div>
            <div className="item">
              <Icon path={Path.mdiEmail} />
              <span>info@livcar.hr</span>
            </div>
          </div>

          <h1 className="title" style={{ margin: '2rem 0' }}>
            <FormattedMessage id="title.businessHours" />
          </h1>

          <div className="card">
            <div className="content">

              <h3 className="day">
                <span><FormattedMessage id="calendar.day.0" /></span>
                <span>08:00 – 19:00</span>
              </h3>
              <h3 className="day">
                <span><FormattedMessage id="calendar.day.1" /></span>
                <span>08:00 – 19:00</span>
              </h3>
              <h3 className="day">
                <span><FormattedMessage id="calendar.day.2" /></span>
                <span>08:00 – 19:00</span>
              </h3>
              <h3 className="day">
                <span><FormattedMessage id="calendar.day.3" /></span>
                <span>08:00 – 19:00</span>
              </h3>
              <h3 className="day">
                <span><FormattedMessage id="calendar.day.4" /></span>
                <span>08:00 – 19:00</span>
              </h3>
              <h3 className="day">
                <span><FormattedMessage id="calendar.day.5" /></span>
                <span>08:00 – 19:00</span>
              </h3>
              <h3 className="day">
                <span><FormattedMessage id="calendar.day.6" /></span>
                <span>08:00 – 19:00</span>
              </h3>
            </div>
          </div>

          <h1 className="title" style={{ margin: '2rem 0' }}>
            <FormattedMessage id="title.map" />
          </h1>

          <iframe
            title="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2781.5982992287963!2d15.996927815940422!3d45.79927487910652!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765d79047bb9a97%3A0xaee23418e1c308d9!2sLivcar%20Rent%20a%20car!5e0!3m2!1sen!2shr!4v1588127170948!5m2!1sen!2shr"
            width="600"
            height="450"
            frameBorder="0"
            style={{
              border: 0,
              borderRadius: '5px',
              width: '100%',
              minHeight: 'calc(100vh - 22rem)',
            }}
            allowFullScreen=""
            aria-hidden="false"
          />

        </Component.Grid>

        <Component.Grid medium={5} large={4}>
          <Component.UsefulLinks />
        </Component.Grid>

      </Component.Container>
    </>
  );
}

Contact.propTypes = {
  language: PropTypes.oneOf(['en', 'hr']).isRequired,
};

export default Contact;
