import React from 'react';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import '../styles/header-toggle.css';

function HeaderToggle({ icon, onClick }) {
  return (
    <button
      type="button"
      className="header-toggle"
      onClick={onClick}
    >
      <Icon path={icon} />
    </button>
  );
}

HeaderToggle.propTypes = {
  icon: PropTypes.node.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default HeaderToggle;
