/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { FormattedMessage } from 'react-intl';
import * as Component from '../components';
import * as Helper from '../helpers';

function Home({
  code,
  language,
  expired,
  thankYou,
}) {
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [category, setCategory] = useState(0);
  const [pickupTime, setPickupTime] = useState(Helper.Time.suggestedPickup);
  const [returnTime, setReturnTime] = useState(Helper.Time.suggestedReturn);
  const [alert, setAlert] = useState({ text: null, type: null });
  const [vehicles, setVehicles] = useState([]);
  const [highlightVehicles, setHighlightVehicles] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const resultCategories = await Helper.Api.get('categories');
        setCategories(resultCategories.data);
        const resultVehicles = await Helper.Api.get('vehicles', { params: { random: true } });
        setVehicles(resultVehicles.data);
        const resultHighlightVehicles = await Helper.Api.get('vehicles', { params: { infoPrice: true } });
        setHighlightVehicles(resultHighlightVehicles.data.sort((prevVehicle, nextVehicle) => {
          const prevMinPrice = prevVehicle.prices.length
            ? (prevVehicle.prices.reduce(
              (min, prevPrice) => Math.min(min, prevPrice.value),
              Infinity,
            )) : prevVehicle.price;
          const nextMinPrice = nextVehicle.prices.length
            ? (nextVehicle.prices.reduce(
              (min, prevPrice) => Math.min(min, prevPrice.value),
              Infinity,
            )) : nextVehicle.price;

          if (prevMinPrice < nextMinPrice) return -1;
          if (prevMinPrice > nextMinPrice) return 1;
          return 0;
        }));
        setLoading(false);
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    })();
  }, []);

  const onSubmit = (event) => {
    event.preventDefault();
    if (!Helper.Time.isReturnAfterPickup(pickupTime, returnTime)) {
      setAlert({
        type: 'danger',
        text: 'error.returnBeforePickup',
      });
    } 
    // else if (!Helper.Time.isTimeSelected(pickupTime)) {
    //   setAlert({
    //     type: 'danger',
    //     text: 'error.isTimeSelected',
    //   });
    // } 
    else {
      navigate(`/search/pickup/${new Date(pickupTime).getTime()}/return/${new Date(returnTime).getTime()}/category/${category}`);
    }
  };

  if (loading) return <Component.Loader />;

  return (
    <>

      <Component.Scroll />
      <Component.Hero code={code} expired={expired} thankYou={thankYou} />

      <form onSubmit={onSubmit}>
        <Component.Container grid>

          <Component.Grid>
            <h1 className="title" style={{ margin: '2rem 0 0 0' }}>
              <FormattedMessage id="title.search" />
            </h1>
          </Component.Grid>

          {alert.text && (
            <Component.Grid>
              <Component.Alert type={alert.type} text={alert.text} />
            </Component.Grid>
          )}

          <Component.Grid medium={6} large={4}>
            <FormattedMessage id="rent.pickupTime">
              {(label) => (
                <Component.DateTime
                  name="pickupTime"
                  label={label}
                  time={pickupTime}
                  setTime={setPickupTime}
                />
              )}
            </FormattedMessage>
          </Component.Grid>

          <Component.Grid medium={6} large={4}>
            <FormattedMessage id="rent.returnTime">
              {(label) => (
                <Component.DateTime
                  name="returnTime"
                  label={label}
                  time={returnTime}
                  setTime={setReturnTime}
                />
              )}
            </FormattedMessage>
          </Component.Grid>

          <Component.Grid medium={6} large={2}>
            <FormattedMessage id="rent.vehicleCategory">
              {(label) => (
                <Component.Select
                  value={category}
                  name="category"
                  label={label}
                  options={categories}
                  onChange={setCategory}
                />
              )}
            </FormattedMessage>
          </Component.Grid>

          <Component.Grid medium={6} large={2}>
            <FormattedMessage id="rent.search">
              {(label) => <Component.Button block>{label}</Component.Button>}
            </FormattedMessage>
          </Component.Grid>

          {/* <Component.Grid>
            <Component.LongTermBanner language={language} />
          </Component.Grid> */}

          {/* <Component.Grid>
            <Component.LongTermNew language={language} />
          </Component.Grid> */}

          <Component.Grid>
            <Component.Carousel data={highlightVehicles} />
          </Component.Grid>

          <Component.Grid>
            <h1 className="title" style={{ margin: '2rem 0 0 0' }}>
              <FormattedMessage id="title.homeTerms" />
            </h1>
          </Component.Grid>

          <Component.Grid>
            <div className="panel">
              {language === 'hr' ? (
                <ul>
                  <li>
                    Neophodna osobna iskaznica ili putovnica te važeća vozačka dozvola.
                    <br />
                    (minimalno 21 godina starosti i posjedovanje
                    vozačke dozvole minimalno 3 godine.)
                  </li>
                  <li>
                    Sigurnosni polog od 130 do 1000 EUR
                    predautorizacijom na kreditnoj kartici.
                    <br />
                    Sigurnosni polog se vraća po povratku vozila ukoliko je
                    vozilo vraćeno u istom stanju u kakvom je i preuzeto,
                    <br />
                    sa svom opremom, jednakim spremnikom goriva
                    kako je naznačeno u ugovoru o najmu vozila.
                  </li>
                  <li>Plaćanje najma se vrši prilikom preuzimanja vozila.</li>
                  <li>
                    Ukoliko se plaćanje vrši bankovnom transakcijom,
                    <br />
                    uplata mora biti izvršena do datuma preuzimanja vozila.
                  </li>
                </ul>
              ) : (
                <ul>
                  <li>
                    A personal identity card or passport and a valid drivers licence are necessary.
                    <br />
                    (minimum 21 years of age and a driving experience of at least 3 years).
                  </li>
                  <li>
                    Security deposit of anywhere from 130 to 1000 EUR via credit card
                    <br />
                    which is refunded immediately after returning the vehicle in original condition
                    <br />
                    with all equipment and accessories to the place and at the
                    time noted in this rental agreement.
                  </li>
                  <li>The payment will be collected upon pickup.</li>
                  <li>
                    Should payment method be by bank transfer,
                    <br />
                    the transaction must be completed before the vehicle is picked up.
                  </li>
                </ul>
              )}
            </div>
          </Component.Grid>

          <Component.Grid>
            <h1 className="title" style={{ margin: '2rem 0 0 0' }}>
              <FormattedMessage id="title.featured" />
            </h1>
          </Component.Grid>

          {vehicles.map((vehicle) => (
            <Component.Grid key={vehicle.id} medium={6} large={4}>
              <Component.VehicleCard data={vehicle} />
            </Component.Grid>
          ))}

        </Component.Container>
      </form>

    </>
  );
}

Home.propTypes = {
  code: PropTypes.node,
  language: PropTypes.oneOf(['hr', 'en']).isRequired,
  expired: PropTypes.bool,
  thankYou: PropTypes.bool,
};

Home.defaultProps = {
  code: false,
  expired: false,
  thankYou: false,
};

export default Home;
