import React from 'react';
import PropTypes from 'prop-types';
import spacetime from 'spacetime';
import Icon from '@mdi/react';
import * as Path from '@mdi/js';
import '../styles/footer.css';
import { Link } from '@reach/router';

const year = spacetime(new Date()).year();

function Footer({ language }) {
  return (
    <div className="footer">
      <div className="item">
        <Icon path={Path.mdiCopyright} />
        <span>
          {year}
          {' '}
          Livcar
        </span>
      </div>
      <div className="item">
        <Icon path={Path.mdiMapMarker} />
        <span>Rapska 12, 10000, Zagreb</span>
      </div>
      <div className="item">
        <Icon path={Path.mdiPhoneClassic} />
        <a href="tel:+385 98 633 893">+385 98 633 893</a>
      </div>
      <div className="item">
        <Icon path={Path.mdiEmail} />
        <a href="mailto:info@livcar.hr">info@livcar.hr</a>
      </div>
      <Link to="/terms" className="item">
        <Icon path={Path.mdiInformationOutline} />
        <span>{language === 'hr' ? 'Uvjeti korištenja' : 'Terms of Service'}</span>
      </Link>
      <Link to="/privacy" className="item">
        <Icon path={Path.mdiInformationOutline} />
        <span>{language === 'hr' ? 'Pravila privatnosti' : 'Privacy Policy'}</span>
      </Link>
      <div className="item">
        <a aria-label="Facebook" href="https://www.facebook.com/livcarzagreb/"><Icon path={Path.mdiFacebook} /></a>
      </div>
      <div className="item">
        <a aria-label="Instaram" href="https://www.instagram.com/livcar_rentacar/"><Icon path={Path.mdiInstagram} /></a>
      </div>
    </div>
  );
}

Footer.propTypes = {
  language: PropTypes.oneOf(['hr', 'en']).isRequired,
};

export default Footer;
